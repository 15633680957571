import { Inject, Injectable, Renderer2, RendererFactory2, ViewEncapsulation } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import environment from '../../environments/environment';

export interface HubSpotConfig {
    portalId: string;
    boardMemberFormId: string;
    propertyMgrFormId: string;
    appLoaderId: string;
    appEmbedId: string;
}

@Injectable({
    providedIn: 'root',
})
export class HubSpotService {
    private config: HubSpotConfig;
    private _renderer: Renderer2;

    constructor(
            private _rendererFactory: RendererFactory2,
            @Inject(DOCUMENT) private document: Document
    ) {
        this._renderer = this._rendererFactory.createRenderer(this.document, {
            id: '-1',
            encapsulation: ViewEncapsulation.None,
            styles: [],
            data: {}
        });

        this.config = {
            portalId: environment.HubSpot.portalId,
            boardMemberFormId: environment.HubSpot.forms.board,
            propertyMgrFormId: environment.HubSpot.forms.pmc,
            appLoaderId: 'hs-script-loader',
            appEmbedId: 'hs-script-forms-embed',
        };
    }

    togglePixel(url: string) {
        if (!environment.production) {
            return;
        }

        // include HubSpot pixel for tracking in non-homeowner sign-up routes
        if (url.indexOf('/sign-up') > -1 && url.indexOf('/sign-up/homeowner') === -1) {
            const scriptElement = this.document.head.querySelector(`#${this.config.appLoaderId}`);
            if (!scriptElement) {
                this.injectTrackingScript();
            }
        } else {
            const scriptElement = this.document.head.querySelector(`#${this.config.appLoaderId}`);
            if (scriptElement) {
                this._renderer.removeChild(this.document.head, scriptElement);
            }
        }
    }

    event(name: string, props: { [prop: string]: any; }) {
        if (!environment.production) {
            return;
        }

        const w = window as any;
        const _hsq = (w._hsq = w._hsq || []);
        _hsq?.push([
            name,
            props,
        ]);
    }

    private injectTrackingScript() {
        // attach hs tracking script to dom
        const script: HTMLScriptElement = this._renderer.createElement('script');
        script.id = this.config.appLoaderId;
        script.type = 'text/javascript';
        script.async = true;
        script.defer = true;
        script.src = `https://js.hs-scripts.com/${this.config.portalId}.js`;
        this._renderer.appendChild(this.document.head, script);
    }
}
