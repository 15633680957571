import { Injectable } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';

@Injectable({
    providedIn: 'root',
})
export class SvgIconService
{
    readonly path = '/assets/images/payhoa';

    private appIcons: { [key: string]: SafeResourceUrl; };
    private registered: string[] = [];

    /** Separating v2 icons out to implement for pmc dashboard for now */
    private appIconsV2: { [key: string]: SafeResourceUrl };

    constructor(
            public matIconRegistry: MatIconRegistry,
            private domSanitizer: DomSanitizer,
    ) {
    }

    /** Separating v2 icons out to implement for pmc dashboard for now */
    registerV2SvgIcons() {
        // Add v2 icons as SVG sprite
        // https://svgsprit.es/
        this.matIconRegistry.addSvgIconSetInNamespace('v2', this.getSvgPath('icon-sprite', 'v2'));

        // Add one-off icons not in SVG sprite
        this.appIconsV2 = {
            // ex. 'fi-br-bell': this.getSvgPath('fi-br-bell', 'v2'),
        };

        for (const icon in this.appIconsV2) {
            if (this.appIconsV2[icon]) {
                if (this.registered.indexOf(`${icon}_v2`) > -1) {
                    console.warn(`${icon} icon is already registered!`);
                    return;
                }

                this.matIconRegistry.addSvgIconInNamespace('v2', icon, this.appIconsV2[icon]);
                this.registered.push(`${icon}_v2`);
            }
        }
    }

    /**
     * Mat Icons are preferred to be set globally (at the app.component level)
     * Still using this global singleton service to handle getting and registering icons for use in entire app
     */
    registerAppMatIcons() {
        this.appIcons = {
            'add': this.getSvgPath('icon_add', 'other'),
            'address': this.getSvgPath('map-pin-silhouette', 'other'),
            'distribute': this.getSvgPath('distribute', 'other'),
            'arrow': this.getSvgPath('icon_arrow', 'other'),
            'bank': this.getSvgPath('icon_bank', 'other'),
            'budget': this.getSvgPath('icon_budget', 'other'),
            'builder': this.getSvgPath('icon_builder', 'other'),
            'delete': this.getSvgPath('icon_delete', 'other'),
            'edit': this.getSvgPath('icon_edit', 'other'),
            'email': this.getSvgPath('icon_email_message', 'unit'),
            'eye': this.getSvgPath('icon_eye', 'other'),
            'export': this.getSvgPath('icon_export', 'other'),
            'hidden': this.getSvgPath('icon_hidden', 'other'),
            'image': this.getSvgPath('icon_image', 'other'),
            'invoice': this.getSvgPath('icon_new_invoices', 'unit'),
            'mailbox': this.getSvgPath('icon_mailbox', 'other'),
            'multiselect': this.getSvgPath('icon_select_arrow', 'other'),
            'pencil': this.getSvgPath('icon_pencil', 'other'),
            'other': this.getSvgPath('icon_other_actions', 'unit'),
            'payment': this.getSvgPath('icon_record_payments', 'unit'),
            'remove': this.getSvgPath('icon_remove', 'other'),
            'reports': this.getSvgPath('icon_reports', 'other'),
            'requests': this.getSvgPath('icon_requests', 'other'),
            'settings': this.getSvgPath('icon_settings', 'other'),
            'star': this.getSvgPath('icon_star', 'other'),
            'suitcase': this.getSvgPath('icon_suitcase', 'other'),
            'tag': this.getSvgPath('icon_tag', 'unit'),
            'text': this.getSvgPath('icon_text_message', 'unit'),
            'transaction': this.getSvgPath('icon_transaction', 'other'),
            'unit': this.getSvgPath('icon_unit', 'other'),
            'user': this.getSvgPath('icon_user', 'other'),
            'vendors': this.getSvgPath('icon_vendors', 'other'),
            'violation': this.getSvgPath('icon_violation', 'other'),
        };

        for (const icon in this.appIcons) {
            if (this.appIcons[icon]) {
                this.registerSvgIcon(icon, this.appIcons[icon]);
            }
        }

        // Add sidemenu icons from SVG sprite
        this.matIconRegistry.addSvgIconSetInNamespace('sidenav', this.getSvgPath('app-sidemenu-sprite'));
    }

    registerMatIconSet(filenames: string[], prefix: string, type: 'unit'|'other'|'landing' = 'other') {
        for (const name of filenames) {
            const icon = prefix + '-' + name.replace('icon_', '');
            this.registerSvgIcon(icon, this.getSvgPath(name, type));
        }
    }

    registerSvgIcon(icon: string, path: SafeResourceUrl) {
        if (this.registered.indexOf(icon) > -1) {
            console.warn(`${icon} icon is already registered!`);
            return;
        }

        this.matIconRegistry.addSvgIcon(icon, path);
        this.registered.push(icon);
    }

    private getSvgPath(filename: string, svgType?: 'unit'|'other'|'landing'|'v2'|null) {
        switch(svgType) {
            case 'other':
                return this.setPath(`${this.path}/other/${filename}.svg`);
            case 'unit':
                return this.setPath(`${this.path}/units/small/${filename}.svg`);
            case 'landing':
                return this.setPath(`${this.path}/hoa-management-software/featureIcons/${filename}.svg`);
            case 'v2':
                return this.setPath(`/assets/images/v2/payhoa/icons/${filename}.svg`);
            default:
                return this.setPath(`${this.path}/${filename}.svg`);
        }
    }

    private setPath(url: string): SafeResourceUrl {
        return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
    }
}
