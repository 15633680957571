import { Injectable } from '@angular/core';
import { ToastaService, ToastOptions } from 'ngx-toasta';
import { ValidationMessageService } from './validation-message.service';

@Injectable({
    providedIn: 'root',
})
export class GrowlerService
{
    constructor(
            private _toastyService: ToastaService,
            private _validationMessageService: ValidationMessageService,
    ) {
    }

    /**
     * @param title
     * @param message
     */
    public success(title: string, message: string): void {
        this.growlMessage(title, message, 'success');
    }

    /**
     * @param title
     * @param message
     */
    public error(title: string, message: string): void {
        this.growlMessage(title, message, 'error');
    }

    public errorFromResponse(response: any, defaultMessage): void {
        const message = this._validationMessageService.extractResponseErrorText(response) || defaultMessage;
        this.oops(message);
    }

    /**
     * @param title
     * @param message
     */
    public oops(message: string): void {
        this.growlMessage('Oops!', message, 'error');
    }

    /**
     * @param title
     * @param message
     */
    public warning(title: string, message: string): void {
        this.growlMessage(title, message, 'warning');
    }

    /**
     * Dispatch growl message to store
     * @param title
     * @param message
     * @param type
     */
    private growlMessage(title: string, message: string, type: string): void {
        const toastOptions: ToastOptions = {
            title: title,
            msg: message,
            showClose: true,
            timeout: 5000,
        };
        switch (type) {
            case 'default':
                this._toastyService.default(toastOptions);
                break;
            case 'info':
                this._toastyService.info(toastOptions);
                break;
            case 'success':
                this._toastyService.success(toastOptions);
                break;
            case 'wait':
                this._toastyService.wait(toastOptions);
                break;
            case 'error':
                this._toastyService.error(toastOptions);
                break;
            case 'warning':
                this._toastyService.warning(toastOptions);
                break;
        }
    }
}
