import { Injectable } from '@angular/core';
import { LocalService } from './storage/local.service';
import { Params } from '@angular/router';

export interface TrackingParams {
    agent: string;
    queryParams: Params;
    referrer: string;
    target: string;
    hutk: string;
}

@Injectable({
    providedIn: 'root',
})
export class TrackingService
{
    public static StorageKey = 'payhoa-tracking';

    public static read(): TrackingParams {
        const data = LocalService.get(TrackingService.StorageKey);
        return JSON.parse(data) as TrackingParams;
    }

    public static clear(): void {
        LocalService.destroy(TrackingService.StorageKey);
    }

    public static store(data: TrackingParams): void {
        LocalService.set(TrackingService.StorageKey, JSON.stringify(data));
    }

}
