<div class="app-logo">
    <a [routerLink]="['/']">
        <img class="img" alt="{{ 'Home' }}" [attr.src]="appLogoSrc"/>
    </a>
</div>

<h1>{{ 'It looks like you are lost...' }}</h1>

<div class="app-error">
    <img class="img" alt="{{ 'Home' }}" [attr.src]="appErrorImage" />
    <a href="/" target="_self" mat-flat-button color="accent" disableRipple>{{ 'Back Home' }}</a>
</div>
