import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Routes } from '../../config/routes';
import { JwtLegFiClaims } from './jwt-legfi-claims.model';
import { ApplicationHttpClient } from '../../components/shared/http/application-http-client';
import { LegFiJwtService } from './legfi-jwt.service';
import { LoginRequest } from './login-request.model';
import { SignupRegistrationRequest } from '../../models/access/signup/signup-registration-request.model';
import { Router } from '@angular/router';
import { IntercomService } from '../intercom.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Location } from '@angular/common';
import { GrowlerService } from '../growler.service';
import { SignupData } from '../../models/access/signup/signup-data.model';
import environment from '../../../environments/environment';

export interface LoginResponse
{
    token?: string;
    twoFactor?: {
        sms: string;
        google: boolean;
        userId: number;
    };
}

export interface SwitchAccountsResponse
{
    siteId?: number;
    external?: boolean;
    nonce?: string;
}

@Injectable({
    providedIn: 'root',
})
export class AuthService
{

    constructor(
            private _http: ApplicationHttpClient,
            private _router: Router,
            private _intercom: IntercomService,
            private _dialog: MatDialog,
            private _location: Location,
            private _growler: GrowlerService,
    ) {
    }

    /**
     * @param {SignupRegistrationRequest} signupRequest
     * @returns {Observable<SignupData>}
     */
    public signup(signupRequest: SignupRegistrationRequest): Observable<SignupData> {
        return this._http.post(
                Routes.MakeLegFiCoreUrl(
                        Routes.LegFiCore.Register,
                ),
                JSON.stringify(signupRequest),
        );
    }

    /**
     * @param {SignupRegistrationRequest} signupRequest
     * @returns {Observable<SignupData>}
     */
    public signupPmc(signupRequest: SignupRegistrationRequest): Observable<SignupData> {
        return this._http.post(
                Routes.MakeLegFiCoreUrl(
                        Routes.LegFiCore.Register + '/pmc',
                ),
                JSON.stringify(signupRequest),
        );
    }

    public registerFromExisting(body: any): Observable<Object> {
        return this._http.post(
                Routes.MakeLegFiCoreUrl(
                        Routes.LegFiCore.RegisterExisting,
                ),
                JSON.stringify(body),
        );
    }

    public getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) {
            return decodeURIComponent(parts.pop().split(';').shift());
        }
        return null;
    }

    public deleteCookie(name: string, domain = '.payhoa.com') {
        if (!this.getCookie(name)) {
            return;
        }

        document.cookie = `${name}=; max-age=0; expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=${domain}; path=/`;
    }

    public getCsrfCookie(): Observable<Object> {
        return this._http.get(Routes.MakeLegFiCoreUrl('/sanctum/csrf-cookie'));
    }

    /**
     * @param {LoginRequest} loginRequest
     * @returns {Observable<Object>}
     */
    public login(loginRequest: LoginRequest): Observable<LoginResponse> {
        return this._http.post(
                Routes.MakeLegFiCoreUrl(
                        Routes.LegFiCore.Login,
                ),
                JSON.stringify(loginRequest),
        );
    }

    /**
     * @param {string} nonce
     * @param {number} orgId
     * @returns {Observable<Object>}
     */
    public nonceLogin(nonce: string, orgId: number): Observable<Object> {
        return this._http.post(
                Routes.MakeLegFiCoreUrl(
                        Routes.LegFiCore.NonceLogin,
                ),
                JSON.stringify({nonce: nonce, orgId: orgId}),
        );
    }

    /**
     * @param {{email:string}} body
     * @returns {Observable<Object>}
     */
    public requestPasswordReset(body: { email: string }): Observable<Object> {
        return this._http.post(
                Routes.MakeLegFiCoreUrl(
                        Routes.LegFiCore.PasswordResetRequest,
                ),
                JSON.stringify(body),
        );
    }

    /**
     * @param {{email:string, password:string, token:string}} body
     * @returns {Observable<Object>}
     */
    public resetPassword(body: {
        [key: string]: string,
        email: string,
        password: string,
        token: string
    }): Observable<Object> {
        body['password_confirmation'] = body.password;
        return this._http.post(
                Routes.MakeLegFiCoreUrl(
                        Routes.LegFiCore.PasswordReset,
                ),
                JSON.stringify(body),
        );
    }

    /**
     * @param {number} orgId
     * @param {any} idParams
     * @returns {SwitchAccountsResponse}
     */
    public switchAccounts(orgId: number, idParams: { [key: string]: number }): Observable<SwitchAccountsResponse> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const params = [];
        if (idParams.siteId) {
            params.push('uiSiteId=' + idParams.siteId);
        }
        if (idParams.unitId) {
            params.push('unitId=' + idParams.unitId);
        }

        const url = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.SwitchContext(orgId) + (params.length > 0 ? '?' + params.join('&') : ''),
        );

        return this._http.get(url);
    }

    /**
     * @param {Object} requestBody
     * @returns {any}
     */
    public upgradeLimitedToken(requestBody: Object): Observable<Object> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.UpgradeToken,
        );

        return this._http.post(url, JSON.stringify(requestBody));
    }

    /**
     * @param phone
     * @returns {any}
     */
    public sendVerificationPin(phone: string): Observable<Object> {
        const requestBody = {
            phone: phone,
        };

        const url = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.SendVerificationPin,
        );

        return this._http.post(url, JSON.stringify(requestBody));
    }


    public renewal(o: number, q: string): Observable<any> {
        let url = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.Renewal,
        );

        url = url + '?o=' + o + '&q=' + q;

        return this._http.get(url);
    }

    /**
     * @param {boolean} preserveLocation
     * @returns {void}
     */
    public logout(preserveLocation: boolean = false): void {
        this._dialog.closeAll();
        this._intercom.shutdown();

        // on logout, do we preserve the uri (and go to sign-in) or redirect back to PayHOALanding?
        // supports implicit vs. explicit logout actions
        if (preserveLocation) {
            // prevent firing multiple growlers
            if (LegFiJwtService.read() !== null) {
                // TODO: Maybe persist message on login screen (specifically for idle timeout)
                this._growler.error('Signed Out', 'You have been logged out of the app. Please, sign in again to continue.');
            }
            LegFiJwtService.clear();

            const uri = this._location.path();
            const tree = this._router.createUrlTree(['/auth/login'], {queryParams: {uri: encodeURIComponent(uri)}});
            this._router.navigateByUrl(tree.toString());
        } else {
            LegFiJwtService.clear();
            window.location.href = environment.PayHOALanding;
        }
    }
}
