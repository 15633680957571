import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RedirectGuard } from './common/auth/redirect.guard';

const routes: Routes = [
    // root navigation will route to login
    { path: '', pathMatch: 'full', redirectTo: 'auth/login' },

    {
        path: 'auth',
        loadChildren: () => import('./components/auth-layout/auth-layout.module').then(m => m.AuthLayoutModule),
    },
    {
        path: 'app',
        loadChildren: () => import('./components/app-layout/app-layout.module.payhoa').then(m => m.AppLayoutModulePayhoa),
    },
    {
        path: 'events',
        loadChildren: () => import('./components/app-layout/app-layout.module').then(m => m.AppLayoutModule),
    },
    {
        path: 'admin',
        loadChildren: () => import('./components/admin-layout/admin-layout.module').then(m => m.AdminLayoutModule),
    },
    {
        path: 'pmc',
        loadChildren: () => import('./components/pmc-layout/pmc-layout.module').then(m => m.PmcLayoutModule),
    },
    {
        path: 'redirect',
        loadChildren: () => import('./components/redirect/redirect.module').then(m => m.RedirectModule),
    },
    {
        path: 'error', loadChildren: () => import('./components/error/error.module').then(m => m.ErrorModule),
    },

    // marketing redirects
    { path: 'community-association-management-software', redirectTo: 'hoa-management-software' },
    { path: 'condo-management-software', redirectTo: 'hoa-management-software' },
    { path: 'hoa-coa-association-management-software-app', redirectTo: 'hoa-management-software' },
    { path: 'hoa-management-software-landing', redirectTo: 'hoa-management-software' },
    { path: 'hoa-management-software-screens', redirectTo: 'hoa-management-software' },
    {
        path: 'hoa-management-software',
        canActivate: [RedirectGuard],
        component: RedirectGuard,
        data: {
            externalUrl: 'https://www.payhoa.com/hoa-software-landing/',
        }
    },

    // catch any unmatched routes and redirect to the error path
    { path: '**', redirectTo: 'error' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule
{ }
