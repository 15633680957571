import { Component } from '@angular/core';

@Component({
    selector: 'app-error',
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.scss'],
})
export class ErrorComponent
{
    readonly appLogoSrc = '/assets/images/v2/payhoa/logo.svg';
    readonly appErrorImage = '/assets/images/v2/payhoa/not-found-404.svg';
}
