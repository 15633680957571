import moment, { Moment } from 'moment-timezone';
import { DepositBankAccount } from './deposit-bank-account';
import { LegFiJwtService } from '../../services/auth/legfi-jwt.service';
import { FixedAsset } from './accounting/fixed-asset';
import { PlaidToken } from './plaid-token';
import { Organization } from './organization';
import { AllianceCredential } from './alliance/alliance-credential';
import { BankReconciliation } from './bank-reconciliation';
import { LobBankAccount } from './lob-bank-account';

export interface HistoricalBalance
{
    createdAt: string;
    balance: number;
}

export class UnifiedBankAccount
{
    id: number;
    organizationId: number;
    friendlyName: string;
    routingNumber: string;
    accountNumber: string;
    last4: string;
    country: string;
    currency: string;
    plaidAccountId: string;
    plaidTokenId: number;
    plaidBalance: number;
    plaidImportAfter: Moment;
    createdAt: Moment;
    updatedAt: Moment;
    deletedAt: Moment;

    depositBankAccount: DepositBankAccount = null;
    fixedAsset: FixedAsset = null;
    plaidToken: PlaidToken;
    plaidAccount: {
        accountId: string;
        mask: string;
        name: string;
        officialName: string;
        type: string;
        subtype: string;
    };
    isAllianceAccount: boolean;
    allianceCredential: AllianceCredential;
    allianceTransactionImportEnabled: boolean;
    allianceTransactionImportAfter: Moment;
    unreviewedTransactionsCount: number;
    organization: Organization;
    plaidBalanceHistory: HistoricalBalance[] = [];
    reconciliations: BankReconciliation[];
    lobBankAccounts: LobBankAccount[];

    constructor(unifiedBankAccount: any) {
        const timezone = LegFiJwtService.getTimezone();

        this.id = unifiedBankAccount.id || null;
        this.organizationId = unifiedBankAccount.organizationId || null;
        this.friendlyName = unifiedBankAccount.friendlyName || '';
        this.routingNumber = unifiedBankAccount.routingNumber || '';
        this.accountNumber = unifiedBankAccount.accountNumber || '';
        this.last4 = unifiedBankAccount.last4 || '';
        this.country = unifiedBankAccount.country || 'US';
        this.currency = unifiedBankAccount.currency || 'usd';
        this.unreviewedTransactionsCount = unifiedBankAccount.unreviewedTransactionsCount || 0;
        this.plaidAccountId = unifiedBankAccount.plaidAccountId || null;
        this.plaidAccount = unifiedBankAccount.plaidAccount || null;
        this.plaidTokenId = unifiedBankAccount.plaidTokenId || null;
        this.plaidToken = unifiedBankAccount.plaidToken ? new PlaidToken(unifiedBankAccount.plaidToken) : null;
        this.plaidImportAfter = unifiedBankAccount.plaidImportAfter
                ? moment.utc(unifiedBankAccount.plaidImportAfter, 'YYYY-MM-DD hh:mm:ss').tz(timezone)
                : null;

        this.createdAt = unifiedBankAccount.createdAt
                ? moment.utc(unifiedBankAccount.createdAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone)
                : null;
        this.updatedAt = unifiedBankAccount.updatedAt
                ? moment.utc(unifiedBankAccount.updatedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone)
                : null;
        this.deletedAt = unifiedBankAccount.deletedAt
                ? moment.utc(unifiedBankAccount.deletedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone)
                : null;

        if (unifiedBankAccount.depositBankAccount) {
            this.depositBankAccount = new DepositBankAccount(unifiedBankAccount.depositBankAccount);
            this.depositBankAccount.unifiedBankAccount = this;
        }
        if (unifiedBankAccount.fixedAsset) {
            this.fixedAsset = new FixedAsset(unifiedBankAccount.fixedAsset);
            this.fixedAsset.owner = this;
        }
        this.organization = unifiedBankAccount.organization ? new Organization(unifiedBankAccount.organization) : null;
        this.isAllianceAccount = unifiedBankAccount.isAllianceAccount || false;
        this.allianceCredential = unifiedBankAccount.allianceCredential
                ? new AllianceCredential(unifiedBankAccount.allianceCredential)
                : null;
        this.allianceTransactionImportEnabled = (this.isAllianceAccount && unifiedBankAccount.allianceTransactionImportEnabled) || false;
        this.allianceTransactionImportAfter = unifiedBankAccount.allianceTransactionImportAfter
                ? moment.utc(unifiedBankAccount.allianceTransactionImportAfter, 'YYYY-MM-DD hh:mm:ss').tz(timezone)
                : null;
        if (unifiedBankAccount.plaidBalanceHistory) {
            this.plaidBalanceHistory = unifiedBankAccount.plaidBalanceHistory;
        }
        if (unifiedBankAccount.reconciliations) {
            this.reconciliations = unifiedBankAccount.reconciliations.map((reconciliation: any) => new BankReconciliation(reconciliation));
        }

        this.plaidBalance = (this.plaidToken || this.isAllianceAccount) && unifiedBankAccount.plaidBalance
                ? unifiedBankAccount.plaidBalance
                : null;

        if (unifiedBankAccount.lobBankAccounts) {
            this.lobBankAccounts = unifiedBankAccount.lobBankAccounts.map(lba => new LobBankAccount(lba));
        }
    }
}
